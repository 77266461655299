export const message = function(message, type, duration) {
    type = type || 'info';
    duration = duration || 4000;

    $.bootstrapGrowl(message, {
        offset: {from: 'bottom', amount: 10},
        type: type,
        delay: duration
    });
};

export const info = function (text, duration) {
    message(text, 'info', duration)
};

export const success = function (text, duration) {
    message(text, 'success', duration)
};

export const error = function (text, duration) {
    message(text, 'danger', duration)
};
